import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  GetAppManifestWrapper,
  TFunction,
} from '@wix/yoshi-flow-editor';

import * as publicApi from './editor/services/public-api';
import { registerGfppEventListeners } from './editor/services/gfpp-event-listener';
import { createAppManifest } from './components/MyAccount/manifest';

let flowAPI: EditorScriptFlowAPI;
let appDefId: string;

const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  _,
  _flowAPI,
) => {
  flowAPI = _flowAPI;
  appDefId = appDefinitionId;

  registerGfppEventListeners(editorSDK);
};

const getAppManifest: GetAppManifestWrapper = async (_options, editorSDK) => {
  return createAppManifest(flowAPI.translations.t as TFunction, editorSDK);
};

const setSettingsParamForWidgets = (
  key: string,
  value: boolean,
  editorSDK: FlowEditorSDK,
) => publicApi.setSettingsParamForWidgets(editorSDK, appDefId, key, value);

const _exports = (editorSDK: FlowEditorSDK) => ({
  public: {
    refreshApp: () => publicApi.refreshApp(editorSDK),
    setAvatarVisibility: (isVisible: boolean) =>
      setSettingsParamForWidgets('avatarVisibility', isVisible, editorSDK),
  },
});
export { editorReady, getAppManifest, _exports as exports };
